@import "colors";
@import "tailwind";
@import "components";

.menu-social-container {
  li {
    a {
      &::before {
        display: inline-block;
        padding-right: 0.4rem;
        color: #fff;
        -webkit-font-smoothing: antialiased;
      }
    }

    a[href*="github.com"] {
      &::before {
        font-family: 'Fontawesome', 'Font Awesome 5 Brands', sans-serif;
        content: '\f113';
      }
    }

    a[href*="twitter.com"] {
      &::before {
        font-family: 'Fontawesome', 'Font Awesome 5 Brands', sans-serif;
        content: '\f099';
      }
    }
  }
}

@layer components {
  .entry-content {
    p {
      @apply mb-4;
    }

    h1 {
      @apply font-bold text-3xl pb-2;
    }

    h2 {
      @apply font-bold text-2xl pb-2;
    }

    h3 {
      @apply font-bold text-xl pb-2;
    }

    h4 {
      @apply font-bold pb-2;
    }

    h5 {
      @apply italic pb-2;
    }
  }

  article {
    &.page,
    &.post,
    &.entry {
      @apply font-light leading-6 text-lg;

      @screen md {
        @apply text-lg;
      }

      .entry-content {
        @apply p-4;
      }
    }

    .more-link {
      @apply block pb-4 font-bold underline text-center;
    }
  }

  .post-nav-links {
    @apply font-bold pt-4 pb-1;

    a.post-page-numbers {
      @apply font-light;

      &:hover {
        @apply underline;
      }
    }
  }

  .posts-navigation,
  .comment-navigation {
    @apply mb-6 mt-2 pr-4 pl-4 font-bold;

    h2.screen-reader-text {
      display: none;
    }

    a:hover {
      @apply underline;
    }

    .nav-links {
      @apply grid grid-cols-2;

      .nav-next {
        @apply text-right;
      }
    }
  }

  .comment-navigation {
    @apply mr-0 pl-0 text-base;
  }

  .comment-body {
    img {
      @apply inline;
    }

    p {
      @apply mb-1 text-base;
    }

    a:hover {
      @apply underline;
    }

    .comment-author {
      .fn {
        @apply font-bold;
      }
    }

    .comment-meta {
      @apply text-sm;
    }

    .reply {
      @apply text-sm font-bold;
    }

    @apply text-base;
  }

  .comment-list {
    li {
      @apply mb-4;
    }

    .children {
      @apply pl-4 pt-2;
    }
  }

  .comment-edit-link {
    @apply font-bold;
  }

  .comment-reply-title a:hover {
    @apply underline;
  }

  .comment-form {
    a:hover {
      @apply underline;
    }

    .logged-in-as {
      a:hover {
        @apply underline;
      }

      @apply text-base font-bold;
    }

    input {
      @apply pl-2 pr-2 max-w-full border-b border-gray-700;
    }

    label {
      @apply font-bold text-base;
    }

    textarea {
      @apply w-full max-w-full p-2 border border-gray-700;
    }

    .form-submit {
      input[type="submit"] {
        @apply p-2 bg-primary text-white rounded-lg;
      }

      @apply text-right;
    }
  }
}
