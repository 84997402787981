@import "colors";

@layer components {
  sp-blog-header {
    .menu-social-container {
      li {
        @apply inline-block text-xl pl-4;

        a {
          &::before {
            @apply text-2xl;
          }
        }
      }
    }
  }

  sp-blog-navbar {
    @apply absolute top-0 left-0 w-full;

    .bg-navbar { background-color: $color-navbar; }

    .menu-social-container {
      @screen md {
        @apply hidden;
      }

      li {
        a {
          &::before {
            @apply text-xl;
          }
        }
      }
    }

    .menu-main-container,
    .menu-social-container {
      @screen md {
        .current-menu-ancestor {
          @apply bg-white text-black;
        }
      }

      .current-menu-item {
        @apply bg-white text-black;
      }

      > ul {
        > li {
          a {
            @apply pl-4;
          }

          li {
            @apply pl-4;
          }

          &:hover {
            @apply bg-white text-black;
          }
        }

        @screen md {
          li a {
            @apply pl-4 pr-4;
          }

          > li {
            .sub-menu {
              @apply absolute float-left hidden;

              li {
                @apply text-white pl-0;

                background-color: $color-navbar;

                &.current-menu-item {
                  @apply bg-white text-black;

                  &:hover {
                    @apply bg-white text-black;
                  }
                }

                &:hover {
                  @apply bg-white text-black;
                }
              }
            }

            &:hover { /* stylelint-disable-line no-descending-specificity */
              .sub-menu {
                @apply block;
              }
            }

            @apply inline-block;
          }
        }
      }
    }

    @screen md {
      @apply static;
    }
  }

  .admin-bar {
    sp-blog-navbar {
      top: 2.875rem;
    }
  }
}

@layer components {
  sp-post-footer {
    @apply pb-2 text-base font-normal leading-8;

    @screen md {
      @apply leading-5;
    }

    a {
      &::before {
        display: inline;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        font-weight: 900;
        -webkit-font-smoothing: antialiased;
        font-family: "Font Awesome 5 Free", "Fontawesome", sans-serif;
        padding-right: 0.5rem;
      }

      &:hover {
        @apply underline;
      }

      @apply pr-6;

      @screen md {
        @apply pr-4;
      }
    }

    .tags a::before {
      content: '\f02b';
    }

    ul.post-categories {
      @apply inline;

      li {
        a::before {
          content: '\f07b';
        }

        @apply inline-block;
      }
    }
  }
}
